import $ from "jquery";
import http from "@/api/axios";
export default {
  name: "",
  components: {},
  data() {
    return {
      activeCase: false,
      type:0,
      caseList: [
        {
          subtitle:'高端装备制造',
          mainBody:'<p style="text-indent:40px">结合制造行业特点，步速者使用信息化、物联网等技术为传统制造行业提供信息化转型及升级服务，涵盖生产制造过程监控与调度系统、生产数据智能处理系统、实时监测平台、智能设施管理平台、故障及安全预警等解决方案，实现工厂生产和制造过程更自动化、高效化、安全化。</p>'
        },{
          subtitle:'交通运输业',
          mainBody:`<p style="text-indent:40px">紧抓枢纽功能提升，是民航的重要工作之一，关乎着我国民航的运输能力与对外开放，枢纽机场更是百姓“飞”往全国以及世界各地的重要载体。 </p>
          <p style="text-indent:40px">由于天气、突发事件、空中交通管制、旅客个人原因、航空公司原因等因素造成的不正常航班延误情况，影响着全球民航业。航延引起的社会关注也伴随着民航业的发展悄然而至。</p>
          <p style="text-indent:40px">步速者已为航空领域的公司提供过机场相关业务管理系统、航空票务相关管理平台、航空公司运营解决方案等。</p>`
        },{
          subtitle:'教育',
          mainBody:'<p style="text-indent:40px">教育是民族振兴、社会进步的重要基石，社会主义现代化建设、社会全面进步、德智体美劳全面发展的社会主义建设者和接班人培养对教育提出了新的更高的要求。步速 者拥有为教育行业的业务信息化系统构建的成熟核心技术，已服务了多个高校为其提供了信息化平台建设的解决方案。</p>'
        },{
          subtitle:'医疗',
          mainBody:`<p style="text-indent:40px">随着我国经济的发展和人民生活水平的提高，我国的医疗消费需求的上升趋势将保持较长时间，市场需求将越来越大。</p>

          <p style="text-indent:40px">同时，随着新医改的深入，第三方医疗服务的兴起带来了大量的需求；大数据、人工智能、互联+引起医疗行业颠覆性的变化，线上咨询、AI问诊、医疗新零售的出现即将可能将取代一些传统的医疗方式；家用医疗器械市场迎来爆炸时期。</p>
          <p style="text-indent:40px">步速者面向医疗行业相关的公司和机构提供过医疗服务管理系统解决方案、医护预约服务解决方案、移动端医患管理解决方案等，助力医疗行业对内的业务信息化管理、提升对外的用户体验，提供高效一流的服务。</p>`
        },{
          subtitle:'传媒',
          mainBody: `<p style="text-indent:40px">传媒行业普遍存在需要批量化的工作，以及需要便捷管理的问题。</p>
          <p style="text-indent:40px">步速者为传统媒体行业提供信息化的解决方案，同时为企业提供自媒体、新媒体自运营的解决方案。</p>`
        },{
          subtitle:'快消费',
          mainBody:`<p style="text-indent:40px">通过对消费者快速消费品市场购买和使用行为连续性的跟踪监测研究，帮助客户更加清晰地解读消费者心理。为客户提供购物者以及消费者群体分析，从而帮助客户建立对核心和潜力消费者购买与使用的认知。通过追踪市场、洞察消费者，我们向客户提供可执行的咨询建议，以及活动建议，从而帮助客户评估品牌表现、新产品上市、制定合理的价格、分析主要零售商表现和优化促销策略，以有效地进行品牌及品类管理。</p>`
        },{
          subtitle:'旅游',
          mainBody:`<p style="text-indent:40px">随着时代的变化，旅游者、出行方式、旅游需求、旅游渠道和产业链都在进行巨变，以往传统的方式已渐渐不被满足，中国旅游已经进入了一个新的发展阶段。步速者结合科技与时代的变化帮助客户打造一整套旅游解决方案，帮助用户快速进入新的阶段。</p>`
        },
      ],
      solveList:[],
      bgList:{},
    };
  },
  methods: {
    openCase(index,item) {
      this.activeCase = index;
      item.mainBody = item.mainBody.replace(/\n/g,"<br/>");
      item.mainBody1 = item.mainBody1.replace(/\n/g,"<br/>");
      this.bgList = item;
      console.log(item);
    },
    alignCenter() {
      const w = document.body.clientWidth;
      const boxes = $(".cover-name")[0];
      if (boxes) {
        const boxWid = boxes.scrollWidth; //获取div的宽
        boxes.style.left = w / 2 - boxWid / 2 + "px";
      }
    },
    getSoluteData(type) {
      const kind = 2;
      if (type===undefined||type===null) {
        type = 0;
      }else{
        document.getElementById("details").scrollIntoView();
      }
      this.type = type;
      http
        .get("/industrySolutions/findAllWeb", {
          params: {
            type,
            kind,
          },
        })
        .then((res) => {
          this.solveList = res.content;
          this.openCase(0,res.content[0]);
        });
    },
  },
  mounted() {
    const _this = this;
    _this.alignCenter();
    let type = sessionStorage.getItem('type');
    _this.getSoluteData(type);
    window.onresize = function() {
      _this.alignCenter();
    };
  },
  beforeDestroy(){
    sessionStorage.removeItem('type');
  },
  computed: {},
  watch: {},
};